<template>
  <v-snackbar
    v-model="snackbar.toggle"
    top
    right
    :color="snackbar.type"
  >
    {{ snackbar.msg }}

    <v-btn
      dark
      text
      @click="snackbar.toggle = false"
    >
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: 'BrSnackbar',
  props: {
    snackbar: {
      type: Object,
      default: () => {
        return {
          toggle: false,
          type: 'success',
          msg: ''
        }
      }
    }
  }
}
</script>